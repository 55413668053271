import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';


  constructor(private router: Router){
    // console.log("SESSION MANAGE", localStorage.getItem('loggedUserInfo'));
    if(localStorage.getItem('loggedUserInfo') != null){
      // this.router.navigate(['Jobseekerlist']);
      // console.log("OLD");
    }
    else if(localStorage.getItem('loggedUserInfo') == null) {
      this.router.navigate(['/authentication/login']);
      // console.log("CLEAR");
    }
  }
}
