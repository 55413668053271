import { NgModule } from '@angular/core';
import { Routes, RouterModule, GuardsCheckEnd } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { AuthGuard } from '../guard/auth.guard';

export const routes: Routes = [
	{
		path: '',
		component: BlankComponent,
		children: [
			{ path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
			{
				path: 'authentication',
				loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
			}
		]
	},
	{
		path: '',
		component: FullComponent,
		children: [
			// { path: '', redirectTo: '/dashboard/dashboard1', pathMatch: 'full' },
			{
				path: 'Jobseekerlist',
				canActivate: [AuthGuard],
				loadChildren: () => import('./pages/jobseekerlist/jobseekerlist.module').then(m => m.JobseekerlistModule)
			},
			{
				path: 'Employerlist',
				canActivate: [AuthGuard],
				loadChildren: () => import('./pages/employerlist/employerlist.module').then(m => m.EmployerlistModule)
			},
			{
				path: 'Skills',
				loadChildren: () => import('./pages/Skills/Skills.module').then(m => m.SkillsModule)
			},
			{
				path: 'Createjob',
				loadChildren: () => import('./pages/Createjob/Createjob.module').then(m => m.CreatejobModule)
			},
			{
				path: 'Logdetails',
				loadChildren: () => import('./pages/logdetails/logdetails.module').then(m => m.LogdetailsModule)
			},
			{
				path: 'ActiveJobs',
				loadChildren: () => import('./pages/activeJobs/activeJobs.module').then(m => m.ActiveJobsModule)
			},
			{
				path: 'JobsTemplate',
				loadChildren: () => import('./pages/jobs/jobs.module').then(m => m.JobsModule)
			},
			{
				path: 'ActivityMap',
				loadChildren: () => import('./pages/activitymap/activitymap.module').then(m => m.ActivitymapModule)
			},
			{
				path: 'Blockinguser',
				loadChildren: () => import('./pages/blockinguser/blockinguser.module').then(m => m.BlockinguserModule)
			}
		]
	},

	{
		path: '**',
		redirectTo: '404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes), NgbModule],
	exports: [RouterModule]
})
export class AppRoutingModule { }
